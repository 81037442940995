import {LitElement, html, TemplateResult, PropertyValues} from 'lit';
import {Analytics} from './elements/analytics';
import {Auth} from './elements/auth';

const analytics = new Analytics();
const auth = new Auth(analytics);

/**
 * `auth-button`
 */
export class AuthButton extends LitElement {
  /**
   * Render in the light dom
   *
   * @returns {object} this
   */
  createRenderRoot() {
    return this;
  }

  /**
   * Defined the elements content
   *
   * @returns {TemplateResult} the resulting html template
   */
  render() {
    return html`
        <button @click=${this._login} ?hidden=${this.isAuthenticated}>Sign Up or Login</button>
        <a href="/register" ?hidden=${!this.isAuthenticated}>Setup</a>
        <button @click=${this._logout} ?hidden=${!this.isAuthenticated}>Log out</button>`;
  }

  /**
   * Defines the elements properties
   *
   * @returns {object} the elements properties
   */
  static get properties() {
    return {
      /** Used to interact with auth */
      auth: {type: Object},
      /** If the user is logged in */
      isAuthenticated: {type: Boolean},
      /** If we should setup the handling of auth */
      setup: {type: Boolean},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.auth = auth;
    this.isAuthenticated = false;
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated(changedProperties);
    }

    if (changedProperties.has('setup') &&
      this.setup && this.auth
    ) {
      this.auth.setup();
      this.auth.onUserChange((userID) => {
        this.isAuthenticated = !!userID;
      });
    }
  }

  /** Login a user */
  async _login() {
    await this.auth.login();
  }

  /** Logout a user */
  async _logout() {
    await this.auth.logout();
    this.isAuthenticated = false;
  }
}
window.customElements.define('auth-button', AuthButton);
